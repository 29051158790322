'use strict';
import './jquery';
import 'slick-carousel';

$(document).ready(function () {
	$('.single-item').slick({
		dots: false,
		infinite: true,
		arrows: false,
		speed: 300,
		slidesToShow: 1,
		autoplay: true,
	});

	$('.left-arrow').click(function () {
		$('.single-item').slick('slickPrev');
	})

	$('.right-arrow').click(function () {
				$('.single-item').slick('slickNext');
	})
});

document.addEventListener('DOMContentLoaded', () => {

	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach(el => {
			el.addEventListener('click', () => {

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');

			});
		});
	}

	var slider = tns({
		container: '#clients',
		items: 2,
		gutter: 30,
		edgePadding: 30,
		nav: false,
		prevButton: false,
		nextButton: false,
		controls: false,
		autoplay: true,
		autoplayButton: false,
		mouseDrag:true,
		autoplayButtonOutput: false,
		responsive: {
			700: {
				items: 3
			},
			900: {
				items: 6
			}
		}
	});

});
